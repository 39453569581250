import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notification = _resolveComponent("notification")!
  const _component_reload_server = _resolveComponent("reload-server")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(_component_notification),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.reloadServer)
          ? (_openBlock(), _createBlock(_component_reload_server, {
              key: 0,
              seconds: _ctx.seconds
            }, null, 8, ["seconds"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}