
import { defineComponent } from 'vue-demi';
import zero from '@/filters/zero.filter';
import { ROLES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: "ReloadServer",
  props: {
    seconds: {
      type: Number,
      required: true
    }
  },
  computed: {
    name() {
      return this.$store.getters.user && this.$store.getters.user.role !== ROLES_ENUM.STUDENT ? 'эксперт' : 'студент'
    },
    secondsFilter() {
      const minutes = Math.floor(this.seconds / 60);
      const seconds = this.seconds % 60;
      return `${zero(minutes)}:${zero(seconds)}`;
    }
  },
  async updated() {
    if (this.seconds === 1) {
      this.$store.dispatch('logout');
      this.$router.push('/auth');
      await this.API.auth.onLogout();
      this.$store.dispatch('logout');
    }
  }
})
