
import { defineAsyncComponent, defineComponent } from "vue-demi";

import Notification from "./components/common/notification/index.vue";
import ReloadServer from "./components/common/reloadServer/index.vue";
const MainLayout = defineAsyncComponent(() => import('./layouts/MainLayout.vue'));
const AuthLayout = defineAsyncComponent(() => import('./layouts/AuthLayout.vue'));
const PreviewLayout = defineAsyncComponent(() => import('./layouts/PreviewLayout.vue'));
const QuestionnaireLayout = defineAsyncComponent(() => import('./layouts/QuestionnaireLayout.vue'));

import { dynamicsObject } from "./interfaces";

export default defineComponent({
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "auth") + "-layout";
    },
  },
  data() {
    return {
      reloadServer: false,
      seconds: 300,
    };
  },
  created() {
    this.socket.on("reloadServer", ({ status, seconds }) => {
      this.reloadServer = status;
      this.seconds = seconds;
    });
    this.checkSaveMode();
  },
  methods: {
    async checkSaveMode() {
      let localSave = localStorage.getItem("saveMode");
      if (localSave) {
        try {
          const save: dynamicsObject = JSON.parse(localSave);
          let data = JSON.parse(save.data);
          if (save.questionnaire && data) {
            await this.API.questionnaire.onSaveMode(data, save.questionnaire);
          }
        } catch (err) {
          console.log(err);
        }
        localStorage.removeItem("saveMode");
        this.$router.push("/auth");
      }
    },
  },
  components: {
    MainLayout,
    AuthLayout,
    PreviewLayout,
    QuestionnaireLayout,
    Notification,
    ReloadServer,
  },
});
